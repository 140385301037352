import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button } from "react-bootstrap"

import { GiBookshelf } from "react-icons/gi"
import { GrDatabase } from "react-icons/gr"

const collaboration = require("../images/collaboration.jpg")

const CollaborationPage = () => (
  <Layout>
    <SEO title="Collaboration" meta={[]} lang="en" description="" />
    <div
      className="mid-hero"
      style={{ backgroundImage: `url(${collaboration})` }}
    >
      <h1 className="text-center white-text outline">
        Collaborate With Flapmax AI Research
      </h1>
    </div>

    {/* Welcome/intro */}
    <section>
      <div className="container">
        <h2 className="text-center mb-5">Welcome innovators.</h2>
        <p className="text-md-center">
          Flapmax has ambitious goals and the vision needed to see them through.
          Flapmax AI Research Fellows are diving deep into the fields of Data
          &amp; Data Management, Applied AI &amp; Machine Learning, and
          Computation &amp; Hardware. Combining forces with individuals from
          various top-class universities, our FAIRES program seeks to make
          Flapmax a leader in technological innovation. We expand our arms to
          any and all impassioned developers, researchers, and organizations.
        </p>
      </div>
    </section>

    {/* What We Do */}
    <section className="bg-darkened">
      <div className="container">
        <h2 className="text-center">What we do at FAIRES</h2>
        <div className="row">
          <div className="col-lg-3 stretch-lg">
            <div className="card responsive-margin">
              <h3 className="underline text-center mb-3">Data</h3>

              <ul>
                <li>Data Management</li>
                <li>Graph Analytics</li>
                <li>Data Science</li>
                <li>Data Mining</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 stretch-lg">
            <div className="card responsive-margin">
              <h3 className="underline text-center mb-3">SciML Applications</h3>

              <ul>
                <li>Computational Biology</li>
                <li>Weather &amp; Climate</li>
                <li>Drug Discovery</li>
                <li>Bioinformatics</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 stretch-lg">
            <div className="card responsive-margin">
              <h3 className="underline text-center mb-3">System Software</h3>

              <ul>
                <li>Cloud Management</li>
                <li>Edge Processing</li>
                <li>Sensors &amp; IoT</li>
                <li>Storage</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 stretch-lg">
            <div className="card responsive-margin">
              <h3 className="underline text-center mb-3">Hardware</h3>

              <ul>
                <li>Reconfigurable Computing</li>
                <li>Hardware Acceleration</li>
                <li>System-on-Chip</li>
                <li>5G Platforms</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="d-flex justify-content-center">
            <Button as={Link} to="/focus">
              More Details
            </Button>
          </div>
        </div>
      </div>
    </section>

    {/* Internal Link outs*/}
    <section>
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-lg-5 stretch-lg">
            <div className="card responsive-margin">
              <div className="d-flex flex-column align-items-center">
                <GiBookshelf size={40} />
                <h3 className="mt-3">Educators</h3>
              </div>

              <p>
                We are continuing to reach out to academia to support our
                ongoing research. Educators and researchers are a fundamental
                component to the Flapmax mission.
              </p>
              <div>
                <Button as={Link} to="/educator">
                  Learn More
                </Button>
              </div>
            </div>
          </div>
          <div className="col-lg-5 stretch-lg">
            <div className="card responsive-margin">
              <div className="d-flex flex-column align-items-center">
                <GrDatabase size={40} />
                <h3 className="mt-3">Industrials</h3>
              </div>

              <p>
                Industry partnerships are helping us accomplish big feats.
                Direct support from companies that share our vision pave the way
                to seeing it through.
              </p>
              <div>
                <Button as={Link} to="/industry">
                  Learn More
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default CollaborationPage
